import { Redirect, Route, Switch } from "react-router-dom";
import Account from "pages/account/Account";
import IBA from "pages/iba/IBA";
import Info from "pages/info/Info";
import Measurements from "pages/measurements/Measurements";
import ReleaseNotes from "pages/releaseNotes/ReleaseNotes";
import RolesDialog from "pages/account/RolesDialog";
import Sidebar from "./Sidebar";
import Webshop from "pages/webshop/Webshop";
import styles from "./Dashboard.module.scss";
import { useAccount } from "api/account";
function Dashboard() {
  const [account] = useAccount();

  return (
    <div className={styles.root}>
      <Sidebar />
      <main>
        <Switch>
          <Route path="/measurements">
            <Measurements />
          </Route>
          <Route exact path="/iba">
            <IBA />
          </Route>
          <Route path="/account">
            <Account />
          </Route>
          <Route path="/webshop">
            <Webshop />
          </Route>
          <Route exact path="/info">
            <Info />
          </Route>
          <Route exact path="/release-notes">
            <ReleaseNotes />
          </Route>
          <Route>
            <Redirect to="/measurements" />
          </Route>
        </Switch>
      </main>
      {account && account.roles?.length === 0 ? <RolesDialog /> : null}
    </div>
  );
}

export default Dashboard;
