import {
  MeasurementsListItem,
  deleteMeasurementGroup,
  deleteMeasurements,
  exportMeasurements,
} from "api/measurements";
import React, { useMemo, useState } from "react";
import { formatGluten, formatPercentage } from "lib/helpers";
import { ReactComponent as ActionsIcon } from "images/actions.svg";
import Checkbox from "components/Checkbox";
import Dropdown from "components/popup/Dropdown";
import Modal from "components/Modal";
import Spinner from "components/Spinner";
import { T } from "lib/language";
import errorImage from "images/error.svg";
import styles from "./Actions.module.scss";
import useMessage from "lib/message";

const options = ["average", "export", "delete"] as const;
type Option = (typeof options)[number];

type ExportFormat = "xlsx" | "csv";

function ListActions({
  measurements,
  onDelete,
}: {
  measurements: MeasurementsListItem[];
  onDelete: () => void;
}) {
  const [selected, setSelected] = useState<Option>();
  const [averageErroMsgKey, setAverageErrorMsgKey] = useState<string>();
  const [deleteMeasurementsChecked, setDeleteMeasurements] = useState(false);
  const onClose = () => setSelected(undefined);

  function Average() {
    const empty = {
      protein: 0,
      moisture: 0,
      carbohydrate: 0,
      oil: 0,
      total: 0,
      gluten_iso: 0 as number | null,
      gluten_gost: 0 as number | null,
      nitrogen: 0 as number | null,
    };

    const sum = measurements.reduce<typeof empty>(
      (sum, m) => ({
        protein: sum.protein + (m.protein === null ? 0 : m.protein),
        moisture: sum.moisture + (m.moisture === null ? 0 : m.moisture),
        carbohydrate:
          sum.carbohydrate + (m.carbohydrate === null ? 0 : m.carbohydrate),
        oil: sum.oil + (m.oil === null ? 0 : m.oil),
        total: sum.total + m.total,
        gluten_iso:
          m.gluten_iso !== null && sum.gluten_iso !== null
            ? sum.gluten_iso + m.gluten_iso
            : null,
        gluten_gost:
          m.gluten_gost !== null && sum.gluten_gost !== null
            ? sum.gluten_gost + m.gluten_gost
            : null,
        nitrogen:
          m.nitrogen !== null && sum.nitrogen !== null
            ? sum.nitrogen + m.nitrogen
            : null,
      }),
      empty
    );

    const count = measurements.length;

    const protein = sum.protein / count;
    const moisture = sum.moisture / count;
    const carbohydrate = sum.carbohydrate / count;
    const oil = sum.oil / count;
    const total = sum.total / count;
    const gluten_iso = sum.gluten_iso !== null ? sum.gluten_iso / count : null;
    const gluten_gost =
      sum.gluten_gost !== null ? sum.gluten_gost / count : null;
    const nitrogen = sum.nitrogen !== null ? sum.nitrogen / count : null;

    return (
      <Modal
        title={<T id="measurements.actions.average.title" />}
        onClose={onClose}
      >
        <ul className={styles.averages}>
          <li>
            <T id="measurement.protein" />
            <span>{formatPercentage(protein)}</span>
          </li>
          <li>
            <T id="measurement.moisture" />
            <span>{formatPercentage(moisture)}</span>
          </li>
          <li>
            <T id="measurement.carbohydrate" />
            <span>{formatPercentage(carbohydrate)}</span>
          </li>
          <li>
            <T id="measurement.oil" />
            <span>{formatPercentage(oil)}</span>
          </li>
          <li>
            <T id="measurement.sampleMass" />
            <span>{total.toFixed(0)} mg</span>
          </li>
          {gluten_iso !== null ? (
            <li>
              <T id="measurement.wetGluten" />{" "}
              <T id="measurement.wetGluten.iso" />
              <span>{formatGluten(gluten_iso)}</span>
            </li>
          ) : null}
          {gluten_gost !== null ? (
            <li>
              <T id="measurement.wetGluten" />{" "}
              <T id="measurement.wetGluten.gost" />
              <span>{formatGluten(gluten_gost)}</span>
            </li>
          ) : null}
          {nitrogen !== null ? (
            <li>
              <T id="measurement.nitrogen" />
              <span>{formatPercentage(nitrogen, " N%")}</span>
            </li>
          ) : null}
        </ul>
      </Modal>
    );
  }

  function Export() {
    const [format, setFormat] = useState<ExportFormat>("xlsx");
    const [sendEmail, setSendEmail] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [downloadButtonDisabled, setDownloadButtonDisabled] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    return (
      <Modal
        title={<T id="measurements.actions.export" />}
        onClose={onClose}
        buttons={
          <button
            className="cta medium"
            disabled={downloadButtonDisabled}
            form="exportForm"
          >
            <T id="download" />
          </button>
        }
      >
        <form
          id="exportForm"
          className={styles.export}
          noValidate
          onSubmit={async (e) => {
            e.preventDefault();

            setDownloadButtonDisabled(true);
            setShowSpinner(true);

            let allMeasurementUUIDs: string[] = measurements
              .filter((m) => !m.isGroup)
              .map((m) => m.uuid);

            measurements
              .filter((m) => m.isGroup)
              .forEach(function (m) {
                m.measurements?.forEach((item) =>
                  allMeasurementUUIDs.push(item)
                );
              });

            exportMeasurements({
              measurements: allMeasurementUUIDs,
              send_email: sendEmail,
              format,
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            })
              .then(() => {
                onClose();
              })
              .catch((e) => {
                setErrorMessage(e.toString());
              })
              .finally(() => {
                setShowSpinner(false);
                setDownloadButtonDisabled(false);
              });
          }}
        >
          <T id="measurements.actions.export.format.description" />:
          <fieldset>
            <label>
              <input
                type="radio"
                checked={format === "xlsx"}
                onChange={(e) => {
                  if (e.target.checked) {
                    setFormat("xlsx");
                  }
                }}
              />
              <T id="measurements.actions.export.format.excel" />
            </label>
            <label>
              <input
                type="radio"
                checked={format === "csv"}
                onChange={(e) => {
                  if (e.target.checked) {
                    setFormat("csv");
                  }
                }}
              />
              <T id="measurements.actions.export.format.csv" />
            </label>
          </fieldset>
          <T id="measurements.actions.export.download.description" />
          <fieldset>
            <label>
              <input
                type="radio"
                checked={!sendEmail}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSendEmail(false);
                  }
                }}
              />
              <T id="measurements.actions.export.download.directDownload" />
            </label>
            <label>
              <input
                type="radio"
                checked={sendEmail}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSendEmail(true);
                  }
                }}
              />
              <T id="measurements.actions.export.download.email" />
            </label>
          </fieldset>
          {errorMessage !== "" ? (
            <p>
              <code>{errorMessage}</code>
            </p>
          ) : null}
          {showSpinner ? <Spinner small={true} /> : null}
        </form>
      </Modal>
    );
  }

  function Delete() {
    const sendMessage = useMessage();

    if (measurements[0].isGroup) {
      let groupsWithMeasurementsCount = measurements.filter(
        (m) => m.protein !== null
      ).length;

      return (
        <Modal
          title={<T id="measurements.actions.deleteGroups.title" />}
          onClose={onClose}
          buttons={
            <>
              <button
                className="cta medium danger"
                onClick={async () => {
                  let input = {
                    uuids: measurements.map((m) => m.uuid),
                    delete_measurements: deleteMeasurementsChecked,
                  };

                  await deleteMeasurementGroup(input)
                    .then(null, () => sendMessage(<T id="delete.fail" />))
                    .finally(() => {
                      onClose();
                      onDelete();
                    });
                }}
              >
                <T id="delete" />
              </button>
              {groupsWithMeasurementsCount > 0 ? (
                <div className={styles.deleteMeasurementGroupDialogCheckbox}>
                  <Checkbox
                    checked={deleteMeasurementsChecked}
                    onClick={() =>
                      setDeleteMeasurements(!deleteMeasurementsChecked)
                    }
                  >
                    <T id="measurements.details.deleteAlsoMeasurements" />
                  </Checkbox>
                </div>
              ) : null}
            </>
          }
        >
          <T
            id="measurements.actions.deleteGroups.warning"
            values={{ number: measurements.length }}
          />
        </Modal>
      );
    } else {
      return (
        <Modal
          title={<T id="measurements.actions.delete.title" />}
          onClose={onClose}
          buttons={
            <button
              className="cta medium danger"
              onClick={async () => {
                await deleteMeasurements(measurements.map((m) => m.uuid))
                  .then(null, () => sendMessage(<T id="delete.fail" />))
                  .finally(() => {
                    onClose();
                    onDelete();
                  });
              }}
            >
              <T id="delete" />
            </button>
          }
        >
          <T
            id="measurements.actions.delete.warning"
            values={{ number: measurements.length }}
          />
        </Modal>
      );
    }
  }

  const deleteDisabled = useMemo(() => {
    const isGroup = measurements[0]?.isGroup;

    return !measurements.every((m) => m.isGroup === isGroup);
  }, [measurements]);

  const averagesDisabled = useMemo(() => {
    const calibration = measurements[0]?.calibration;
    const isGroup = measurements[0]?.isGroup;
    let output = false;

    if (!measurements.every((m) => m.calibration === calibration)) {
      setAverageErrorMsgKey("measurements.actions.average.error");
      output = true;
    } else if (!measurements.every((m) => m.isGroup === isGroup)) {
      setAverageErrorMsgKey("measurements.actions.average.grouperror");
      output = true;
    }

    return output;
  }, [measurements]);

  function renderOption(option: Option) {
    if (option === "average") {
      return averagesDisabled ? (
        <div className={styles.averagesDisabled}>
          <img src={errorImage} alt="error" />
          <T id="measurements.actions.average" />
          <div className={styles.averagesError}>
            <img src={errorImage} alt="error" />
            <T id={averageErroMsgKey} />
          </div>
        </div>
      ) : (
        <T id="measurements.actions.average" />
      );
    } else if (option === "export") {
      return <T id="measurements.actions.export" />;
    } else {
      return <T id="delete" />;
    }
  }

  return (
    <>
      <Dropdown
        disabled={measurements.length === 0}
        options={[...options]}
        getKey={(o) => o}
        getDisabled={(o) =>
          (o === "average" && averagesDisabled) ||
          (o === "delete" && deleteDisabled)
        }
        render={renderOption}
        onSelect={setSelected}
      >
        <>
          <ActionsIcon />
          <T id="measurements.actions" />
        </>
      </Dropdown>

      {selected === "average" ? (
        <Average />
      ) : selected === "delete" ? (
        <Delete />
      ) : selected === "export" ? (
        <Export />
      ) : null}
    </>
  );
}

export default ListActions;
