import React, { useState } from "react";
import { classes } from "lib/helpers";
import styles from "./FormInput.module.scss";
import { useField } from "./Form";

export type Props = JSX.IntrinsicElements["input"] & {
  styleSize?: "large" | "medium";
  onChangeValue?: React.Dispatch<React.SetStateAction<string>>;
};

function FormInput({
  styleSize = "large",
  className,
  onBlur,
  onInput,
  onInvalid,
  onSubmit,
  onChangeValue,
  children,
  ...props
}: Props) {
  const fieldProps = useField(props.name);
  const [error, setError] = useState("");
  const [blured, setBlured] = useState(false);

  return (
    <label
      className={classes(
        styles.root,
        styleSize === "medium" && styles.medium,
        className
      )}
    >
      {children}
      <input
        className={classes(blured && styles.blured)}
        onBlur={(e) => {
          if (onBlur) {
            onBlur(e);
          }
          setError(e.currentTarget.validationMessage);
          setBlured(true);
        }}
        onInput={(e) => {
          if (onInput) {
            onInput(e);
          }
          e.currentTarget.setCustomValidity("");
          setError(e.currentTarget.validationMessage);
        }}
        onInvalid={(e) => {
          if (onInvalid) {
            onInvalid(e);
          }
          setError(e.currentTarget.validationMessage);
          setBlured(true);
        }}
        onChange={(e) => {
          onChangeValue!(e.target.value);
        }}
        {...fieldProps}
        {...props}
      />
      {error && blured ? <div className={styles.errors}>{error}</div> : null}
    </label>
  );
}

export default FormInput;
