import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { ibaCalculatorContext, initRowCount, maxRowCount } from "pages/Context";
import { DeviceCorrection } from "api/iba";
import { T } from "lib/language";
import { calibrationHasOilCorrection } from "lib/calibrations";
import { classes } from "lib/helpers";
import styles from "./IBA.module.scss";

export default function IBACalculator({
  correction,
  calibration,
  onClickButton,
  submitButtonRef,
}: {
  correction: DeviceCorrection | null | undefined;
  calibration: string;
  onClickButton: (
    newIbaProtein: number | undefined,
    newIbaMoisture: number | undefined,
    newIbaOil: number | undefined
  ) => void;
  submitButtonRef: React.RefObject<HTMLButtonElement>;
}) {
  const [fieldValues, setFieldValues] = useState<{ [key: string]: number }>({});
  const [newIbaProtein, setNewIbaProtein] = useState<number>();
  const [newIbaMoisture, setNewIbaMoisture] = useState<number>();
  const [newIbaOil, setNewIbaOil] = useState<number>();
  const [rowCount, setRowCount] = useState<number>(initRowCount);
  const hasOil = calibrationHasOilCorrection(calibration);

  return (
    <ibaCalculatorContext.Provider
      value={{
        hasOil,
        fieldValues,
        setFieldValues,
        newIbaProtein,
        setNewIbaProtein,
        newIbaMoisture,
        setNewIbaMoisture,
        newIbaOil,
        setNewIbaOil,
        rowCount,
        setRowCount,
        correction,
        calibration,
        onClickButton,
        maxRowCount,
        submitButtonRef,
      }}
    >
      <CalculatorForm />
    </ibaCalculatorContext.Provider>
  );
}

function CalculatorForm() {
  const {
    calibration,
    rowCount,
    setRowCount,
    fieldValues,
    setFieldValues,
    correction,
    setNewIbaProtein,
    setNewIbaMoisture,
    setNewIbaOil,
    hasOil,
  } = useContext(ibaCalculatorContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    () =>
      doNewIbaCalculation(
        fieldValues,
        rowCount,
        correction,
        setNewIbaProtein,
        setNewIbaMoisture,
        setNewIbaOil
      ),
    [
      calibration,
      fieldValues,
      rowCount,
      correction,
      setNewIbaProtein,
      setNewIbaMoisture,
      setNewIbaOil,
    ]
  );

  const maxRowsReached = () => rowCount === maxRowCount;

  const resetCalculatorForm = () => {
    setFieldValues({});
    setNewIbaProtein(undefined);
    setNewIbaMoisture(undefined);
    setNewIbaOil(undefined);
  };

  return (
    <>
      <ValuesContainer resetCalculatorForm={resetCalculatorForm} />

      <fieldset className={classes(styles.main, styles.liftUp)}>
        <div
          className={classes(
            styles.calculator,
            hasOil ? styles.calculatorHasOil : null
          )}
        >
          <label className={styles.header}></label>
          <label
            className={classes(
              styles.header,
              styles.title,
              hasOil ? styles.titleHasOil : null
            )}
          >
            <T id={"iba.calculator.reference"} />
          </label>

          {[...Array.from(Array(3).keys())].map((num, i) => (
            <CalculatorTitle key={i} colNumber={i + 1} />
          ))}

          <div className={styles.sampleNumberTitle}>
            <T id={"iba.calculator.sample"} />
          </div>

          {[...Array.from(Array(4).keys())].map((num, i) => (
            <SubHeaders
              style={i % 2 === 0 ? styles.darker : undefined}
              key={i}
            />
          ))}

          {[...Array.from(Array(rowCount).keys())].map((num, i) => (
            <CalculatorRow key={i} rowNumber={i + 1} />
          ))}
        </div>
      </fieldset>

      <div className={styles.buttonContainer}>
        <button
          type="button"
          className={classes("cta medium", styles.reset)}
          onClick={() => {
            resetCalculatorForm();
          }}
        >
          <T id="iba.calculator.clear" />
        </button>

        <button
          type="button"
          disabled={maxRowsReached()}
          onClick={() => {
            setRowCount(rowCount + 1);
          }}
          className={classes("cta medium", hasOil ? null : styles.pullLeft)}
        >
          +
        </button>
      </div>
    </>
  );
}

function CalculatorTitle({ colNumber }: { colNumber: number }) {
  const { hasOil } = useContext(ibaCalculatorContext);

  return (
    <>
      <label
        className={classes(
          styles.header,
          styles.title,
          hasOil ? styles.titleHasOil : null
        )}
      >
        <T id={"iba.calculator.grainsense-measurement"} /> {colNumber}
      </label>
    </>
  );
}

function getCalculatorFieldData(
  fieldValues: { [key: string]: number },
  rowCount: number
) {
  let calculatorData = [];

  for (let rowNumber = 1; rowNumber <= rowCount; rowNumber++) {
    let referenceProtein = fieldValues[Number(rowNumber).toString() + ".0.0"];
    let referenceMoisture = fieldValues[Number(rowNumber).toString() + ".0.1"];
    let referenceOil = fieldValues[Number(rowNumber).toString() + ".0.2"];

    let measurementsProtein = [];
    let measurementsMoisture = [];
    let measurementsOil = [];
    let value = undefined;

    for (let item = 1; item <= 3; item++) {
      if (referenceProtein !== undefined) {
        value =
          fieldValues[
            [Number(rowNumber).toString(), Number(item).toString(), "0"].join(
              "."
            )
          ];

        if (value !== undefined) {
          measurementsProtein.push(value);
        }
      }

      if (referenceMoisture !== undefined) {
        value =
          fieldValues[
            [Number(rowNumber).toString(), Number(item).toString(), "1"].join(
              "."
            )
          ];

        if (value !== undefined) {
          measurementsMoisture.push(value);
        }
      }

      if (referenceOil !== undefined) {
        value =
          fieldValues[
            [Number(rowNumber).toString(), Number(item).toString(), "2"].join(
              "."
            )
          ];

        if (value !== undefined) {
          measurementsOil.push(value);
        }
      }
    }

    if (
      referenceProtein !== undefined ||
      referenceMoisture !== undefined ||
      referenceOil !== undefined
    ) {
      let rowData: {
        reference: { protein: number; moisture: number; oil: number };
        measurements: {
          protein: number[];
          moisture: number[];
          oil: number[];
        };
      } = {
        reference: {
          protein: referenceProtein,
          moisture: referenceMoisture,
          oil: referenceOil,
        },
        measurements: {
          protein: measurementsProtein,
          moisture: measurementsMoisture,
          oil: measurementsOil,
        },
      };

      calculatorData.push(rowData);
    }
  }

  return calculatorData;
}

function doNewIbaCalculation(
  fieldValues: { [key: string]: number },
  rowCount: number,
  correction: DeviceCorrection | null | undefined,
  setNewIbaProtein: Dispatch<SetStateAction<number | undefined>>,
  setNewIbaMoisture: Dispatch<SetStateAction<number | undefined>>,
  setNewIbaOil: Dispatch<SetStateAction<number | undefined>>
) {
  let fieldData = getCalculatorFieldData(fieldValues, rowCount);

  if (fieldData.length > 0 && fieldData !== undefined) {
    let rowCalculated = [];

    for (let i = 0; i < fieldData.length; i++) {
      let proteinMeasuredAvg =
        fieldData[i].measurements.protein.reduce((a, b) => {
          return a + b;
        }, 0) / fieldData[i].measurements.protein.length;

      let moistureMeasuredAvg =
        fieldData[i].measurements.moisture.reduce((a, b) => {
          return a + b;
        }, 0) / fieldData[i].measurements.moisture.length;

      let oilMeasuredAvg =
        fieldData[i].measurements.oil.reduce((a, b) => {
          return a + b;
        }, 0) / fieldData[i].measurements.oil.length;

      rowCalculated.push({
        protein:
          fieldData[i].reference.protein !== undefined &&
          proteinMeasuredAvg !== undefined
            ? Number(fieldData[i].reference.protein) -
              proteinMeasuredAvg +
              (correction !== null ? correction!.protein_offset : 0)
            : null,
        moisture:
          fieldData[i].reference.moisture !== undefined &&
          moistureMeasuredAvg !== undefined
            ? Number(fieldData[i].reference.moisture) -
              moistureMeasuredAvg +
              (correction !== null ? correction!.moisture_offset : 0)
            : null,
        oil:
          fieldData[i].reference.oil !== undefined &&
          oilMeasuredAvg !== undefined
            ? Number(fieldData[i].reference.oil) -
              oilMeasuredAvg +
              (correction !== null ? correction!.oil_offset : 0)
            : null,
      });
    }

    let filteredDataProtein = rowCalculated.filter(
      ({ protein }) => protein !== undefined && protein !== null
    );

    let avgProtein =
      filteredDataProtein.reduce((r, c) => r + c.protein!, 0) /
      filteredDataProtein.length;

    let filteredDataMoisture = rowCalculated.filter(
        ({ moisture }) => moisture !== undefined && moisture !== null
      ),
      avgMoisture =
        filteredDataMoisture.reduce((r, c) => r + c.moisture!, 0) /
        filteredDataMoisture.length;

    let filteredDataOil = rowCalculated.filter(
        ({ oil }) => oil !== undefined && oil !== null
      ),
      avgOil =
        filteredDataOil.reduce((r, c) => r + c.oil!, 0) /
        filteredDataOil.length;

    if (!Number.isNaN(avgProtein)) {
      setNewIbaProtein(avgProtein);
    }

    if (!Number.isNaN(avgMoisture)) {
      setNewIbaMoisture(avgMoisture);
    }

    if (!Number.isNaN(avgOil)) {
      setNewIbaOil(avgOil);
    }
  }
}

function ValuesContainer({
  resetCalculatorForm,
}: {
  resetCalculatorForm: () => void;
}) {
  const {
    newIbaProtein,
    newIbaMoisture,
    newIbaOil,
    hasOil,
    onClickButton,
    submitButtonRef,
  } = useContext(ibaCalculatorContext);

  function newIbaButtonDisabled() {
    let returnValue = true;

    if (
      newIbaProtein !== undefined ||
      newIbaMoisture !== undefined ||
      (newIbaOil !== undefined && hasOil)
    ) {
      returnValue = false;
    }

    return returnValue;
  }

  return (
    <div className={styles.valuesContainer}>
      <div className={classes(styles.title, styles.fullwidth)}>
        <T id={"iba.calculator.new-iba"} />
      </div>

      <div>
        <T id={"measurement.protein"} />
      </div>
      <div className={styles.value}>
        {newIbaProtein?.toFixed(1).replace(/^-0\.0$/, "0.0")}
      </div>

      <div>
        <T id={"measurement.moisture"} />
      </div>
      <div className={styles.value}>
        {newIbaMoisture?.toFixed(1).replace(/^-0\.0$/, "0.0")}
      </div>

      {hasOil ? (
        <>
          <div>
            <T id={"measurement.oil"} />
          </div>
          <div className={styles.value}>
            {newIbaOil?.toFixed(1).replace(/^-0\.0$/, "0.0")}
          </div>
        </>
      ) : null}

      <div className={styles.fullwidth}>
        <button
          type={"button"}
          onClick={() => {
            if (onClickButton !== undefined) {
              onClickButton(newIbaProtein, newIbaMoisture, newIbaOil);
              setTimeout(() => submitButtonRef?.current?.click(), 1000);
              resetCalculatorForm();
            }
          }}
          className={"cta medium"}
          disabled={newIbaButtonDisabled()}
        >
          <T id={"iba.calculator.use"} />
        </button>
      </div>
    </div>
  );
}

function SubHeaders({ style }: { style: string | undefined }) {
  const { hasOil } = useContext(ibaCalculatorContext);

  return (
    <>
      <div className={classes(styles.subheader, style)}>
        <T id={"measurement.protein"} />
      </div>
      <div className={classes(styles.subheader, style)}>
        <T id={"measurement.moisture"} />
      </div>
      {hasOil ? (
        <div className={classes(styles.subheader, style)}>
          <T id={"measurement.oil"} />
        </div>
      ) : null}
    </>
  );
}

function CalculatorRow({ rowNumber }: { rowNumber: number }) {
  const { hasOil } = useContext(ibaCalculatorContext);

  return (
    <>
      <div className={styles.sampleNumberColumn}>#{rowNumber}</div>

      <div className={classes(styles.fieldColumn, styles.darker)}>
        <CalculatorField rowNumber={rowNumber} colNumber={0} placeInCol={0} />
      </div>
      <div className={classes(styles.fieldColumn, styles.darker)}>
        <CalculatorField rowNumber={rowNumber} colNumber={0} placeInCol={1} />
      </div>
      {hasOil ? (
        <div className={classes(styles.fieldColumn, styles.darker)}>
          <CalculatorField rowNumber={rowNumber} colNumber={0} placeInCol={2} />
        </div>
      ) : null}

      <div className={styles.fieldColumn}>
        <CalculatorField rowNumber={rowNumber} colNumber={1} placeInCol={0} />
      </div>
      <div className={styles.fieldColumn}>
        <CalculatorField rowNumber={rowNumber} colNumber={1} placeInCol={1} />
      </div>
      {hasOil ? (
        <div className={styles.fieldColumn}>
          <CalculatorField rowNumber={rowNumber} colNumber={1} placeInCol={2} />
        </div>
      ) : null}

      <div className={classes(styles.fieldColumn, styles.darker)}>
        <CalculatorField rowNumber={rowNumber} colNumber={2} placeInCol={0} />
      </div>
      <div className={classes(styles.fieldColumn, styles.darker)}>
        <CalculatorField rowNumber={rowNumber} colNumber={2} placeInCol={1} />
      </div>
      {hasOil ? (
        <div className={classes(styles.fieldColumn, styles.darker)}>
          <CalculatorField rowNumber={rowNumber} colNumber={2} placeInCol={2} />
        </div>
      ) : null}

      <div className={styles.fieldColumn}>
        <CalculatorField rowNumber={rowNumber} colNumber={3} placeInCol={0} />
      </div>
      <div className={styles.fieldColumn}>
        <CalculatorField rowNumber={rowNumber} colNumber={3} placeInCol={1} />
      </div>
      {hasOil ? (
        <div className={styles.fieldColumn}>
          <CalculatorField rowNumber={rowNumber} colNumber={3} placeInCol={2} />
        </div>
      ) : null}
    </>
  );
}

function CalculatorField({
  rowNumber,
  colNumber,
  placeInCol,
}: {
  rowNumber: number;
  colNumber: number;
  placeInCol: number;
}) {
  const {
    fieldValues,
    setFieldValues,
    rowCount,
    correction,
    setNewIbaProtein,
    setNewIbaMoisture,
    setNewIbaOil,
  } = useContext(ibaCalculatorContext);

  let id =
    Number(rowNumber).toString() +
    "." +
    Number(colNumber).toString() +
    "." +
    Number(placeInCol).toString();

  return (
    <input
      type="number"
      key={id}
      size={4}
      name={id}
      step={0.1}
      value={fieldValues[id] !== undefined ? fieldValues[id] : ""}
      onKeyDown={(e) => {
        if (
          e.key !== "-" &&
          e.key !== "Tab" &&
          e.key !== "Shift" &&
          e.key !== "Backspace" &&
          e.key !== "," &&
          e.key !== "." &&
          Array.from(
            Array.from(Array(10).keys(), (x) => x.toString())
          ).includes(e.key) === false
        ) {
          e.preventDefault();
        }
      }}
      onChange={(e) => {
        const value = e.target.valueAsNumber;

        if (!isNaN(value)) {
          setFieldValues((fieldValues) => ({ ...fieldValues, [id]: +value }));
          doNewIbaCalculation(
            fieldValues,
            rowCount,
            correction,
            setNewIbaProtein,
            setNewIbaMoisture,
            setNewIbaOil
          );
          e.target.focus();
        }
      }}
    />
  );
}
