import { Choice, Role, updateRoles, useRoles } from "api/account";
import { useEffect, useState } from "react";
import Modal from "components/Modal";
import { T } from "lib/language";
import styles from "./RolesDialog.module.scss";
import { useIntl } from "react-intl";

type SelectedRoles = {
  uuid: string;
  choices: { uuid: string }[] | any;
}[];

function RolesDialog() {
  const intl = useIntl();
  const [roles] = useRoles();
  const [selectedRoles, setSelectedRoles] = useState<SelectedRoles>([]);
  const [rolesDialogHidden, setRolesDialogHidden] = useState<boolean>(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    checkSelectedRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoles]);

  function saveRoles(): void {
    if (checkSelectedRoles()) {
      updateRoles({ roles: selectedRoles }).then(() =>
        setRolesDialogHidden(true)
      );
    }
  }

  function checkSelectedRoles(): boolean {
    setMessage("");
    let output = true;

    selectedRoles.forEach((role) => {
      let originalRole = roles?.find((item) => item.uuid === role.uuid);
      if (originalRole?.is_multi_choice && role.choices.length === 0) {
        setMessage(
          originalRole.name_tr +
            " " +
            String(intl.messages["roles.dialog.message.requiresMoreDetails"])
        );
        output = false;
      }
    });

    return output;
  }

  function collectSelectedRoles(): void {
    let selected: SelectedRoles = [];
    let checkboxes = document.querySelectorAll(
      "form#selectedRoles input[type=checkbox]:checked"
    );

    checkboxes.forEach((checkbox: any) => {
      let uuid = checkbox.value!;

      let relatedSelectElement: NodeList = document.querySelectorAll(
        "select[name='" + uuid + "']"
      );
      let el = relatedSelectElement[0] as HTMLSelectElement;

      let choices = null;
      if (el !== undefined) {
        choices = [];

        for (let i = 0; i < el.selectedOptions.length; i++) {
          let opt = el.selectedOptions[i] as HTMLOptionElement;

          choices.push({ uuid: opt.value });
        }
      }

      selected.push({ uuid: uuid, choices: choices });
    });

    setSelectedRoles(selected);
  }

  return rolesDialogHidden ? null : (
    <div className={styles.rolesDialog}>
      <Modal
        title={<T id="roles.dialog.title" />}
        onClose={() => {}}
        buttons={
          <>
            <button
              className="cta medium"
              disabled={selectedRoles.length === 0 ? true : false}
              onClick={() => saveRoles()}
            >
              <T id="save" />
            </button>
          </>
        }
      >
        <div className={styles.message}>{message}</div>
        <form id="selectedRoles">
          {roles?.map((item: Role) => {
            return (
              <div key={item.uuid} className={styles.checkboxrow}>
                <div>
                  <input
                    type="checkbox"
                    key={item.uuid}
                    name="selectedRole"
                    value={item.uuid}
                    onChange={collectSelectedRoles}
                  />
                  <label htmlFor={item.uuid}>{item.name_tr}</label>
                </div>
                <div>
                  {item.choices.length > 0 ? (
                    <select
                      name={item.uuid}
                      multiple={item.is_multi_choice!}
                      onChange={collectSelectedRoles}
                    >
                      {item.choices.map((choice: Choice) => {
                        return (
                          <option value={choice.uuid} key={choice.uuid}>
                            {choice.name_tr}
                          </option>
                        );
                      })}{" "}
                    </select>
                  ) : null}
                </div>
              </div>
            );
          })}
        </form>
      </Modal>
    </div>
  );
}

export default RolesDialog;
