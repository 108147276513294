import {
  CalibrationImg,
  CalibrationText,
  calibrationHasGlutenGOSTCorrection,
  calibrationHasGlutenISOCorrection,
  calibrationHasOilCorrection,
} from "lib/calibrations";
import { FormattedDate, FormattedTime } from "react-intl";
import {
  Location,
  Measurement,
  deleteAttachment,
  deleteMeasurement,
  revertLocation,
  updateAttachment,
  updateAttachmentSchema,
  updateComment,
  updateCommentSchema,
  updateLocation,
  useMeasurement,
} from "api/measurements";
import Map, { MapToggle } from "./Map";
import React, { useMemo, useState } from "react";
import { classes, formatGluten, formatPercentage } from "lib/helpers";
import { useHistory, useParams } from "react-router-dom";
import Collapse from "components/Collapse";
import { ReactComponent as Delete } from "images/delete.svg";
import { ReactComponent as DeleteAttachment } from "images/delete.svg";
import Form from "components/Form";
import Modal from "components/Modal";
import Spinner from "components/Spinner";
import { T } from "lib/language";
import TextArea from "components/TextArea";
import popupArrow from "images/popupArrow.svg";
import styles from "./Details.module.scss";
import stylesDetailsCommon from "./DetailsCommon.module.scss";
import useMessage from "lib/message";

function Basis({ basis }: { basis: number | null }) {
  function BasisText() {
    if (basis === null) {
      return <T id="measurement.wet" />;
    } else if (basis === 0) {
      return <T id="measurement.dry" />;
    } else {
      return <T id="measurement.fixed" values={{ basis }} />;
    }
  }
  return (
    <small>
      <BasisText />
    </small>
  );
}

function getAttachmentName(fullname: string) {
  let output = fullname.split("/").reverse()[0];
  output = output.substring(37, output.length); // Remove uuid
  output = output.split("?")[0]; // Remove possible query string

  return output;
}

function Attachment({ measurement: m }: { measurement: Measurement }) {
  let [counter, setState] = useState(0);
  const sendMessage = useMessage();

  return (
    <div className={classes(stylesDetailsCommon.attachmentContainer)}>
      {m.attachment ? (
        <div>
          <div className={stylesDetailsCommon.customTitle}>
            <T id="measurements.details.attachment" />
          </div>
          <a href={m.attachment} target="_blank" rel="noreferrer">
            {getAttachmentName(m.attachment)}
          </a>
          <DeleteAttachment
            height={14}
            width={16}
            onClick={async () => {
              await deleteAttachment(m.uuid)
                .then(function () {
                  m.attachment = "";
                  setState(counter + 1);
                })
                .then(
                  function () {
                    sendMessage(
                      <T id="measurements.details.deleteAttachment.success" />
                    );
                  },
                  function () {
                    sendMessage(
                      <T id="measurements.details.deleteAttachment.fail" />
                    );
                  }
                );
            }}
          />
        </div>
      ) : (
        <div>
          <div className={stylesDetailsCommon.customTitle}>
            <div className={stylesDetailsCommon.customTitle}>
              <T id="measurements.details.attachment" />
            </div>
          </div>
          -
        </div>
      )}

      <Form
        schema={updateAttachmentSchema}
        onSubmit={async (data) => {
          await updateAttachment(m.uuid, data)
            .then((response) => {
              m.attachment = response.attachment;
              setState(counter + 1);
            })
            .then(
              function () {
                sendMessage(
                  <T id="measurements.details.updateAttachment.success" />
                );
              },
              function () {
                sendMessage(
                  <T id="measurements.details.updateAttachment.fail" />
                );
              }
            );
        }}
      >
        <label className="cta small">
          <input
            type="file"
            id="attachment"
            name="attachment"
            accept="image/png, image/jpeg"
          />
          <button type="submit" className="cta small">
            <T id="submit" />
          </button>
        </label>
      </Form>
    </div>
  );
}

function Compounds({ measurement: m }: { measurement: Measurement }) {
  return (
    <ul>
      <li>
        <div className={stylesDetailsCommon.iconProtein} />
        <div>
          <T id="measurement.protein" />
          <Basis basis={m.protein_fixed_percentage} />
        </div>
        <div className={stylesDetailsCommon.percentageContainer}>
          <strong>{formatPercentage(m.protein)}</strong>
        </div>
      </li>
      <li>
        <div className={stylesDetailsCommon.iconMoisture} />
        <div>
          <T id="measurement.moisture" />
          <Basis basis={m.moisture_fixed_percentage} />
        </div>
        <div className={stylesDetailsCommon.percentageContainer}>
          <strong>{formatPercentage(m.moisture)}</strong>
        </div>
      </li>
      <li>
        <div className={stylesDetailsCommon.iconCarbohydrate} />
        <div>
          <T id="measurement.carbohydrate" />
          <Basis basis={m.carbohydrate_fixed_percentage} />
        </div>
        <div className={stylesDetailsCommon.percentageContainer}>
          <strong>{formatPercentage(m.carbohydrate)}</strong>
        </div>
      </li>
      <li>
        <div className={stylesDetailsCommon.iconOil} />
        <div>
          <T id="measurement.oil" />
          <Basis basis={m.oil_fixed_percentage} />
        </div>
        <div className={stylesDetailsCommon.percentageContainer}>
          <strong>{formatPercentage(m.oil)}</strong>
        </div>
      </li>
    </ul>
  );
}

function OffsetNumber({
  value,
  value2 = null,
  isStrong = false,
}: {
  value: number | null;
  value2?: number | null;
  isStrong?: boolean;
}) {
  const sum =
    value === null ? value2 : value2 === null ? value : value + value2;

  return React.createElement(
    isStrong ? "strong" : "div",
    {
      className: classes(
        sum && sum > 0 && styles.positive,
        sum && sum < 0 && styles.negative
      ),
    },
    sum === null ? "-" : sum.toFixed(1)
  );
}

function DetailsCollapse({ measurement: m }: { measurement: Measurement }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button
        className={classes(
          "ctaLink medium",
          stylesDetailsCommon.detailsButton,
          open && stylesDetailsCommon.open
        )}
        onClick={() => setOpen(!open)}
      >
        <T id="measurements.details.details" />
        <img src={popupArrow} alt="Details" />
      </button>

      <Collapse open={open}>
        <SingleMeasurementDetails measurement={m} />
      </Collapse>
    </>
  );
}

function Input({ measurement: m }: { measurement: Measurement }) {
  const sendMessage = useMessage();
  return (
    <div className={styles.input}>
      <Form
        schema={updateCommentSchema}
        onSubmit={async (data) => {
          await updateComment(m.uuid, data).then(
            function () {
              sendMessage(
                <T id="measurements.details.updateComment.success" />
              );
            },
            function () {
              sendMessage(<T id="measurements.details.updateComment.fail" />);
            }
          );
        }}
      >
        <TextArea
          name="comment"
          rows={3}
          styleSize="medium"
          defaultValue={m.comment}
        >
          <T id="measurement.comment" />
        </TextArea>
        <button type="submit" className="cta medium">
          <T id="measurements.details.updateComment" />
        </button>
      </Form>
    </div>
  );
}

function SingleMeasurementDetails({
  measurement: m,
}: {
  measurement: Measurement;
}) {
  const hasOilCorrection = calibrationHasOilCorrection(m.calibration);
  const hasGlutenISOCorrection = calibrationHasGlutenISOCorrection(
    m.calibration
  );
  const hasGlutenGOSTCorrection = calibrationHasGlutenGOSTCorrection(
    m.calibration
  );

  return (
    <>
      <ul>
        <li>
          <div>
            <T id="measurement.sampleMass" />
          </div>
          <strong>{m.total.toFixed(0)} mg</strong>
        </li>
        {m.hectolitre !== undefined && m.hectolitre !== null ? (
          <li>
            <div>
              <T id="measurement.hectolitreweight" />
            </div>
            <strong>{m.hectolitre?.toFixed(1)} kg</strong>
          </li>
        ) : null}
        {m.nitrogen !== null ? (
          <li>
            <div>
              <T id="measurement.nitrogen" />
            </div>
            <strong>{formatPercentage(m.nitrogen, " N%")}</strong>
          </li>
        ) : null}
        {m.gluten_iso !== null ? (
          <li>
            <div>
              <T id="measurement.wetGluten" />
              <small>
                <T id="measurement.wetGluten.iso" />
              </small>
            </div>
            <strong>{formatGluten(m.gluten_iso)}</strong>
          </li>
        ) : null}
        {m.gluten_gost !== null ? (
          <li>
            <div>
              <T id="measurement.wetGluten" />
              <small>
                <T id="measurement.wetGluten.gost" />
              </small>
            </div>
            <strong>{formatGluten(m.gluten_gost)}</strong>
          </li>
        ) : null}
        {m.sunflower_unpeeled_estimate_moisture !== null &&
        m.sunflower_unpeeled_estimate_moisture !== undefined ? (
          <li>
            <div>
              <T id="measurement.sunflowerUnpeeledEstimate.moisture" />
            </div>
            <strong>
              {formatPercentage(m.sunflower_unpeeled_estimate_moisture)}
            </strong>
          </li>
        ) : null}
        {m.sunflower_unpeeled_estimate_oil !== null &&
        m.sunflower_unpeeled_estimate_oil !== undefined ? (
          <li>
            <div>
              <T id="measurement.sunflowerUnpeeledEstimate.oil" />
            </div>
            <strong>
              {formatPercentage(m.sunflower_unpeeled_estimate_oil)}
            </strong>
          </li>
        ) : null}
      </ul>
      <div className={styles.offsets}>
        <div className={styles.offsetsTitle}>
          <T id="measurements.details.offsets" />:
          <small>
            <T id="measurements.details.offsets.description" />
          </small>
        </div>

        <div className={styles.grid}>
          <div />
          <div>
            <T id="measurement.areaOffset" />
          </div>
          <div>
            <T id="measurement.deviceOffset" />
          </div>
          <strong>
            <T id="measurement.totalOffset" />
          </strong>

          <div className={styles.compound}>
            <T id="measurement.protein" />
          </div>
          <OffsetNumber value={m.protein_area_offset} />
          <OffsetNumber value={m.protein_device_offset} />
          <OffsetNumber
            value={m.protein_area_offset}
            value2={m.protein_device_offset}
            isStrong
          />

          <div className={styles.compound}>
            <T id="measurement.moisture" />
          </div>
          <OffsetNumber value={m.moisture_area_offset} />
          <OffsetNumber value={m.moisture_device_offset} />
          <OffsetNumber
            value={m.moisture_area_offset}
            value2={m.moisture_device_offset}
            isStrong
          />

          {hasOilCorrection ? (
            <>
              <div className={styles.compound}>
                <T id="measurement.oil" />
              </div>
              <OffsetNumber value={m.oil_area_offset} />
              <OffsetNumber value={m.oil_device_offset} />
              <OffsetNumber
                value={m.oil_area_offset}
                value2={m.oil_device_offset}
                isStrong
              />
            </>
          ) : null}

          {hasGlutenISOCorrection ? (
            <>
              <div className={classes(styles.glutenOffset, styles.compound)}>
                <T id="measurement.wetGluten" />
                <small>
                  <T id="measurement.wetGluten.iso" />
                </small>
              </div>
              <OffsetNumber value={m.gluten_iso_offset} />
              <OffsetNumber value={m.gluten_iso_offset} isStrong />
            </>
          ) : null}
          {hasGlutenGOSTCorrection ? (
            <>
              <div className={classes(styles.glutenOffset, styles.compound)}>
                <T id="measurement.wetGluten" />
                <small>
                  <T id="measurement.wetGluten.gost" />
                </small>
              </div>
              <OffsetNumber value={m.gluten_gost_offset} />
              <OffsetNumber value={m.gluten_gost_offset} isStrong />
            </>
          ) : null}
        </div>
        {m.area_correction_created_at ? (
          <small>
            <T id="measurements.details.offsets.areaCreatedAt" />{" "}
            <FormattedDate value={m.area_correction_created_at} />
          </small>
        ) : null}
        {m.device_correction_created_at ? (
          <small>
            <T id="measurements.details.offsets.deviceCreatedAt" />{" "}
            <FormattedDate value={m.device_correction_created_at} />
          </small>
        ) : null}
      </div>
    </>
  );
}

function DetailsLoaded({ measurement: m }: { measurement: Measurement }) {
  const [deleteOpened, setDeleteOpened] = useState(false);
  const hist = useHistory();
  const sendMessage = useMessage();

  return (
    <div className={stylesDetailsCommon.details}>
      <div className={stylesDetailsCommon.title}>
        <CalibrationImg calibration={m.calibration} />
        <h3>
          <CalibrationText calibration={m.calibration} />
        </h3>
        <div className={stylesDetailsCommon.info}>
          <FormattedDate value={m.measured_at} />
          <br />
          <FormattedTime timeStyle={"medium"} value={m.measured_at} />
        </div>
        <div className={stylesDetailsCommon.info}>
          <T id="measurements.details.gsAnalyzerSN" />:
          <br />
          {m.device}
        </div>
        <Delete onClick={() => setDeleteOpened(true)} />
      </div>
      <div className={stylesDetailsCommon.body}>
        <div className={stylesDetailsCommon.data}>
          <Compounds measurement={m} />
          <DetailsCollapse measurement={m} />
        </div>
        <div>
          <div>
            <Input measurement={m} />
          </div>
          <div>
            <Attachment measurement={m} />
          </div>
        </div>
      </div>
      {deleteOpened ? (
        <Modal
          title={<T id="measurements.details.delete" />}
          onClose={() => setDeleteOpened(false)}
          buttons={
            <button
              className="cta medium danger"
              onClick={async () => {
                await deleteMeasurement(m.uuid)
                  .then(
                    () => {
                      hist.push("/measurements");
                    },
                    () => sendMessage(<T id="delete.fail" />)
                  )
                  .finally(() => setDeleteOpened(false));
              }}
            >
              <T id="delete" />
            </button>
          }
        >
          <T id="measurements.details.delete.warning" />
        </Modal>
      ) : null}
    </div>
  );
}

function DetailsMap({
  measurement,
  open,
  onClose,
}: {
  measurement: Measurement;
  open: boolean;
  onClose: () => void;
}) {
  const sendMessage = useMessage();
  const [dbLocation, setDbLocation] = useState<Location>();
  const [newLocation, setNewLocation] = useState<google.maps.LatLng>();
  const m = useMemo(
    () => ({ ...measurement, ...dbLocation }),
    [measurement, dbLocation]
  );
  const [resetToBounds, setResetToBounds] = useState(true);

  return (
    <div
      className={classes("mapBackground", open && "open")}
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
    >
      <div className="map">
        <div className="mapControlls">
          <button
            className="revert"
            onClick={async () => {
              await revertLocation(m.uuid).then(
                function (result) {
                  sendMessage(<T id="measurements.details.map.success" />);
                  setDbLocation(result);
                  setNewLocation(undefined);
                },
                function () {
                  sendMessage(<T id="measurements.details.map.fail" />);
                }
              );
            }}
          >
            <T id="measurements.details.map.revert" />
          </button>
          {newLocation ? (
            <button
              className="cta medium save"
              onClick={async () => {
                await updateLocation(m.uuid, {
                  gps_latitude: newLocation.lat(),
                  gps_longitude: newLocation.lng(),
                }).then(
                  function (result) {
                    sendMessage(<T id="measurements.details.map.success" />);
                    setDbLocation(result);
                    setNewLocation(undefined);
                  },
                  function () {
                    sendMessage(<T id="measurements.details.map.fail" />);
                  }
                );
              }}
            >
              <T id="save" />
            </button>
          ) : null}
        </div>

        <Map
          measurement={m}
          draggable
          onDrag={setNewLocation}
          resetToBounds={resetToBounds}
          setResetToBounds={setResetToBounds}
        />
      </div>
    </div>
  );
}

function Details() {
  const { uuid } = useParams<{ uuid: string }>();
  const [measurement, , errors] = useMeasurement(uuid);
  const [mapOpen, setMapOpen] = useState(false);
  const history = useHistory();

  if (!measurement && errors.length === 0) {
    return <Spinner />;
  }

  if (measurement === undefined) {
    return (
      <div className={stylesDetailsCommon.errormessage}>
        <b>Server responded: </b>
        <code>{errors.length > 0 ? errors[0] : ""}</code>
      </div>
    );
  } else {
    return (
      <>
        <h1>
          <T id="measurements" />
        </h1>
        <div className={classes("dashboardTop", stylesDetailsCommon.top)}>
          <div className="ctaLink" onClick={() => history.goBack()}>
            <T id="back" />
          </div>
          <MapToggle open={mapOpen} onOpen={setMapOpen} />
        </div>
        <div className={stylesDetailsCommon.root}>
          <div className={stylesDetailsCommon.detailsContainer}>
            <DetailsLoaded measurement={measurement} />
          </div>
          <DetailsMap
            measurement={measurement}
            open={mapOpen}
            onClose={() => setMapOpen(false)}
          />
        </div>
      </>
    );
  }
}

export default Details;
